import React, { useState, createContext } from 'react';

const initialState = {
  donateOpen: false,
  donateType: 'General Donation',
  donateCopy: '',
  donateListId: '',
  filters: {
    locations: [],
    artists: [],
    dates: {
      from: '',
      to: '',
    },
    series: [],
    searchQuery: '',
  },
  blogFilters: {
    label: false,
  },
  artistFilters: {
    instruments: [],
  },
  activeModule: '',
  subscriptionOpen: false,
  selectedStep: '',
};
export const StateContext = createContext();

const StateProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <StateContext.Provider value={{ state, setState }}>
      {children}
    </StateContext.Provider>
  );
};

const useSiteContext = () => {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error(`useSiteContext must be used within a SiteProvider`);
  }
  return context;
};

function usePopupOpen() {
  const { setState } = useSiteContext();

  function toggleDonateOpen(value) {
    setState((prevState) => {
      return {
        ...prevState,
        donateOpen: value,
      };
    });
  }
  return toggleDonateOpen;
}

function useSubscriptionOpen() {
  const { setState } = useSiteContext();

  function toggleDonateOpen(value) {
    setState((prevState) => {
      return {
        ...prevState,
        subscriptionOpen: value,
      };
    });
  }
  return toggleDonateOpen;
}

function useUpdateSubscriptionStep() {
  const { setState } = useSiteContext();

  function updateSubscriptionStep(value) {
    setState((prevState) => {
      return {
        ...prevState,
        selectedStep: value,
      };
    });
  }
  return updateSubscriptionStep;
}

function useDonateType() {
  const { setState } = useSiteContext();

  function updateDonateType(value) {
    setState((prevState) => {
      return {
        ...prevState,
        donateType: value,
      };
    });
  }
  function updateDonateListId(value) {
    setState((prevState) => {
      return {
        ...prevState,
        donateListId: value,
      };
    });
  }
  function updateDonateCopy(value) {
    setState((prevState) => {
      return {
        ...prevState,
        donateCopy: value,
      };
    });
  }
  return { updateDonateType, updateDonateCopy, updateDonateListId };
}

//Update the state with the new filters
function useFilters() {
  const { setState } = useSiteContext();

  function updateLocation(value) {
    setState((prevState) => {
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          //append to array and remove if already in array
          locations: prevState.filters.locations.includes(value)
            ? prevState.filters.locations.filter((item) => item !== value)
            : [...prevState.filters.locations, value],
        },
      };
    });
  }
  function updateArtist(value) {
    setState((prevState) => {
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          //append to array and remove if already in array
          artists: prevState.filters.artists.includes(value)
            ? prevState.filters.artists.filter((item) => item !== value)
            : [...prevState.filters.artists, value],
        },
      };
    });
  }
  function updateDate(from, to) {
    setState((prevState) => {
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          dates: {
            from,
            to,
          },
        },
      };
    });
  }
  function updateSeries(value) {
    setState((prevState) => {
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          //append to array and remove if already in array
          series: prevState.filters.series.includes(value)
            ? prevState.filters.series.filter((item) => item !== value)
            : [...prevState.filters.series, value],
        },
      };
    });
  }
  function updateSearchQuery(value) {
    setState((prevState) => {
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          searchQuery: value,
        },
      };
    });
  }
  function updateBlogLabel(value) {
    setState((prevState) => {
      return {
        ...prevState,
        blogFilters: {
          ...prevState.blogFilters,
          label: value,
        },
      };
    });
  }
  function updateArtistInstruments(value) {
    setState((prevState) => {
      return {
        ...prevState,
        artistFilters: {
          ...prevState.artistFilters,
          //append to array and remove if already in array
          instruments: prevState.artistFilters.instruments.includes(value)
            ? prevState.artistFilters.instruments.filter(
                (item) => item !== value,
              )
            : [...prevState.artistFilters.instruments, value],
        },
      };
    });
  }
  function updateActiveModule(value) {
    setState((prevState) => {
      return {
        ...prevState,
        activeModule: value,
      };
    });
  }
  function nukeFilters() {
    setState((prevState) => {
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          locations: [],
          artists: [],
          dates: {
            from: '',
            to: '',
          },
          series: [],
          searchQuery: '',
        },
        blogFilters: {
          ...prevState.blogFilters,
          label: false,
        },
        artistFilters: {
          ...prevState.artistFilters,
          instruments: [],
        },
      };
    });
  }

  return {
    updateLocation,
    updateArtist,
    updateDate,
    updateSeries,
    updateSearchQuery,
    nukeFilters,
    updateBlogLabel,
    updateArtistInstruments,
    updateActiveModule,
  };
}

export {
  StateProvider,
  useSiteContext,
  usePopupOpen,
  useFilters,
  useDonateType,
  useSubscriptionOpen,
  useUpdateSubscriptionStep,
};
